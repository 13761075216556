<template>
  <v-layout>
    <MasterdataAppBar />

    <v-container fluid class="px-0 pt-5">
      <v-col cols="11" xl="8" lg="9" md="11" class="mx-auto px-0 pt-0">
        <v-row dense class="align-center justify-center">
          <v-card tile outlined class="flex-grow-1">
            <v-col cols="12">
              <v-card-title class="px-2">
                <v-row dense align="center">
                  <v-col cols="12">
                    <v-toolbar-title class="d-flex flex-shrink-1">
                      Artikelschnitte
                    </v-toolbar-title>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider inset></v-divider>
              <v-card-text class="px-2 subtitle-2">
                <v-row dense class="align-center justify-center">
                  <v-col cols="12">
                    <v-card tile outlined class="flex-grow-1">
                      <v-col cols="12">
                        <v-card-title class="px-2">
                          <v-row dense>
                            <v-col cols="auto">
                              <v-text-field
                                dense
                                hide-details
                                v-model="filter.search"
                                prepend-icon="mdi-magnify"
                                clearable
                                placeholder="Suche"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-title>
                        <v-divider inset></v-divider>
                        <v-card-text class="px-2 subtitle-2">
                          <v-row dense>
                            <v-col cols="12">
                              <v-data-table
                                v-model="selected"
                                height="calc(48px + (48px * 5))"
                                fixed-header
                                hide-default-footer
                                :items="datasets"
                                item-key="Schnitt_ID"
                                :items-per-page="25"
                                :page.sync="page"
                                @page-count="pageCount = $event"
                                :headers="datasetHeaders"
                                :search="filter.search"
                                :single-select="true"
                                show-select
                                @item-selected="assignDataset"
                                mobile-breakpoint="300"
                                style="width: 100%;"
                              >
                                <template #[`item.Schnitt`]="{ item }">
                                  <span class="font-weight-bold"> {{ item.Schnitt }}</span>
                                </template>
                              </v-data-table>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-divider inset></v-divider>
                        <v-card-actions>
                          <v-row dense align="center">
                            <v-col cols="12">
                              <v-pagination color="grey darken-2" v-model="page" :length="pageCount"></v-pagination>
                            </v-col>
                          </v-row>
                        </v-card-actions>
                      </v-col>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xl="5" lg="6" md="6" sm="8">
                    <v-row>
                      <v-col cols="12" v-if="selected.length == 0">
                        <v-row dense>
                          <v-col cols="12">
                            <span class="font-weight-bold">Schnitt</span>
                            <v-text-field
                              v-model="newDataset.Schnitt"
                              hide-details
                              placeholder="Schnitt"
                              color="primary"
                              outlined
                              clearable
                              :error="checkSchnitt"
                              ><template v-slot:append>
                                <v-icon v-if="!checkSchnitt && newDataset.Schnitt" color="green lighten-2">mdi-check</v-icon>
                                <v-icon v-else-if="checkSchnitt && newDataset.Schnitt" color="red lighten-2">mdi-alert</v-icon>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="pt-4">
                            <v-btn
                              @click="speichern(newDataset)"
                              block
                              color="green lighten-2 grey--text text--lighten-4"
                              :disabled="!newDataset.Schnitt || checkSchnitt"
                            >
                              <v-icon>mdi-plus</v-icon>
                              <span class="ml-1">Neu</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" v-else>
                        <v-row dense>
                          <v-col cols="12">
                            <span class="font-weight-bold">Schnitt</span>
                            <v-text-field
                              v-model="editableDataset.Schnitt"
                              hide-details
                              placeholder="Schnitt"
                              color="primary"
                              outlined
                              clearable
                              :error="checkSchnitt"
                              ><template v-slot:append>
                                <v-icon v-if="!checkSchnitt && editableDataset.Schnitt" color="green lighten-2">mdi-check</v-icon>
                                <v-icon v-else-if="checkSchnitt && editableDataset.Schnitt" color="red lighten-2">mdi-alert</v-icon>
                              </template>
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" class="pt-4">
                            <v-btn
                              block
                              color="orange lighten-2 grey--text text--lighten-4"
                              @click="speichern(editableDataset)"
                              :disabled="!editableDataset.Schnitt || checkSchnitt"
                            >
                              <v-icon>mdi-pencil</v-icon>
                              <span class="ml-1">Bearbeiten</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-card>
        </v-row>
      </v-col>
    </v-container>
  </v-layout>
</template>

<script>
import MasterdataAppBar from "../../../components/appbars/MasterdataAppBar.vue";

export default {
  components: {
    MasterdataAppBar,
  },

  mounted() {
    this.initialize();
  },

  data() {
    return {
      filter: {
        search: "",
      },
      datasetHeaders: [],
      hiddenColumns: ["Schnitt_ID"],
      datasets: [],
      page: 1,
      pageCount: 0,
      selected: [],
      newDataset: {},
      editableDataset: {},
    };
  },

  computed: {
    checkSchnitt() {
      const findname = this.datasets.filter((item) => item.Schnitt === this.newDataset.Schnitt || item.Schnitt === this.editableDataset.Schnitt);
      if (findname.length > 0) {
        return true;
      } else return false;
    },
  },

  methods: {
    async initialize() {
      const response = await fetch(`/api/settings/masterdata/cuts`);
      const json = await response.json();

      for (let [key, value] of Object.entries(json)) {
        this[key] = value;
      }

      const filterableHeaders = [
        true, //Schnitt
      ];
      const alignmentHeaders = ["start"];
      const dividerHeaders = [false];

      this.datasetHeaders = Object.keys(this.datasets[0])
        .filter((it) => this.hiddenColumns.indexOf(it) === -1)
        .map((it, i) => {
          return {
            text: it,
            value: it,
            sortable: false,
            filterable: filterableHeaders[i],
            align: alignmentHeaders[i],
            divider: dividerHeaders[i],
            class: "text--primary text-uppercase",
          };
        });
    },

    assignDataset({ item, value }) {
      if (value == true) {
        this.editableDataset = Object.assign({}, item);
        this.newDataset = {};
      } else this.editableDataset = {};
    },

    async speichern(dataset) {
      if (!dataset.Schnitt_ID) {
        const datasetToInsert = {};

        if (Object.keys(datasetToInsert).length === 0) {
          Object.assign(datasetToInsert, {
            Schnitt: dataset.Schnitt,
          });
        }

        await fetch("/api/settings/masterdata/cut/dataset/insert", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datasetToInsert),
        });

        this.$root.actionSnackbar.show({
          dataset: "Schnitt",
          action: "erstellt",
        });
      } else if (dataset.Schnitt_ID) {
        const datasetToUpdate = {};

        if (Object.keys(datasetToUpdate).length === 0) {
          Object.assign(datasetToUpdate, {
            Schnitt_ID: dataset.Schnitt_ID,
            Schnitt: dataset.Schnitt,
          });
        }

        await fetch("/api/settings/masterdata/cut/dataset/update", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datasetToUpdate),
        });

        this.$root.actionSnackbar.show({
          dataset: "Schnitt",
          action: "bearbeitet",
        });
      }

      this.editableDataset = {};
      this.newDataset = {};
      this.selected = [];
      this.initialize();
    },
  },
};
</script>
